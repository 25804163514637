import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  z-index: 500;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 720px;
  padding: 0 20px;

  > span {
    text-align: center;
    margin-top: 16px;
  }
`;

export const DivButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  width: 100%;
  max-width: 400px;

  > button {
    height: 50px;
    border-radius: 8px;
    font-weight: 500;
    border: none;
  }
`;

export const DivLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CancelButton = styled.button`
  background: rgba(255, 48, 60, 0.06);
  color: #ff303c;
`;

export const PaymentButton = styled.button`
  background: #0d85ff;
  color: #ffffff;
  margin-top: 12px;
`;

export const DivConfirmCancelPlan = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DivConfirmCancelPlanContainer = styled.div`
  position: fixed;
  background: #000;
  opacity: 0.1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const DivConfirmCancelPlanBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  background: #fff;
  position: absolute;
  top: 35%;
  border-radius: 20px;
  padding: 24px 20px;

  > strong {
    color: #000;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }

  > span {
    color: #838383;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    margin-top: 8px;
  }

  @media (max-width: 720px) {
    top: auto;
    bottom: 0;
    border-radius: 20px 20px 0 0;
  }
`;

export const DivConfirmCancelPlanButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  > button {
    height: 50px;
    border-radius: 8px;
    font-weight: 500;
    border: none;
  }
`;

export const CancelPlanButton = styled.button``;

export const ConfirmCancelPlanButton = styled.button`
  margin-top: 12px;
  background: #ff303c;
  color: #ffffff;
`;
