import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* background: rgb(255, 255, 255); */
  padding: 32px;
  border-radius: 16px;
  /* width: 1280px !important; */
  width: 100% !important;
  align-items: center;

  > span {
    margin-top: 8px;
    font-size: 13px;
    font-weight: 400 !important;
  }
`;

export const DiscountLabel = styled.div`
  border-radius: 24px;
  background: #00d47b;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 16px;
  margin-bottom: 16px;
`;

export const DivProPlan = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  border-radius: 18px;
  box-shadow: 0px 8px 90px -30px rgba(0, 0, 0, 0.11);
  margin-top: 40px;
  padding: 32px 46px;
  padding-bottom: 64px;
`;

export const DivPlanOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  padding: 0 20px;
  margin-top: 24px;
`;

export const DivProCards = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;

  > div {
    display: flex;

    margin-top: 16px;
    background: #fafafa;

    > div {
      padding-left: 16px;
    }

    /* display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch; */
  }
`;

export const DivButtonsPlanOptions = styled.div`
  display: flex;
  width: 100%;
  border-radius: 100px;
  background: #efefef;
  height: 50px;
  padding: 4px;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    border-radius: 100px;
    border: none;
  }
`;

export const MonthlyButton = styled.button`
  background: #efefef;
  color: #000000;

  ${props =>
    props.active &&
    css`
      background: #ffffff;
      font-weight: 600;
      color: #000000;
    `}
`;

export const YearlyButton = styled.button`
  background: #efefef;
  color: #000000;

  ${props =>
    props.active &&
    css`
      font-weight: 600;
      color: #000000;
      background: #ffffff;
    `}

  > div {
    border-radius: 24.5px;
    background: var(
      --Cores-new-gradiet,
      linear-gradient(220deg, #ff6534 12.15%, #ff0e9f 64.72%, #a925fa 102.33%)
    );
    font-size: 11px;
    color: #fff;
    font-weight: 500;
    padding: 2px 6px;
    margin-left: 6px;
  }
`;

export const DivDiscount = styled.div`
  display: flex;
  color: #ff6c4b;
  margin-top: 24px;

  > span {
    margin-left: 6px;
  }
`;

export const DivLoader = styled.div`
  margin-top: 32px;
`;

export const CardPlan = styled.div`
  padding: 24px 20px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 18px;
  background: #ffffff;
  border: 0.5px solid #d8dee5;
  height: max-content;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #101010;
    margin-bottom: 1px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  > span {
    font-size: 13px;
    line-height: 18px;
    color: #acacac;
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

export const DivColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* max-width: 360px; */

  & + div {
    margin-left: 32px;
  }
`;

export const DivPrice = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  > strong {
    font-size: 37px;
    font-style: normal;
    font-weight: 800;
    color: #00d47b;
    margin-bottom: 4px;

    > span {
      font-size: 13px;
      color: #00d47b;
      font-weight: 500;
    }
  }

  > span {
    font-weight: 400;
    font-size: 13px;
    text-align: center;
    text-decoration-line: line-through;
    color: #9d9d9d;
  }
`;

export const GreyLabel = styled.div`
  background: #efefef;
  border-radius: 100px;
  padding: 4px 16px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    color: #101010;
    text-align: center;
    font-size: 11px;
    font-weight: 400;
  }
`;

export const LabelPromotion = styled.div`
  display: flex;
  position: absolute;
  top: 24px;
  right: 20px;
  background: #34c780;
  border-radius: 70px;
  padding: 4px 10px;

  > span {
    font-weight: 500;
    font-size: 13px;
    color: #ffffff;
  }
`;

export const DivItemsPro = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 24px;

  .breve {
    color: #bfbfbf !important;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 56px;
    border: none;
    background: #0d85ff;
    border-radius: 8px;
    color: #fff;
    font-size: 15px;
    position: relative;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 8px;

    > img {
      position: absolute;
      right: 20px;
    }

    &:disabled {
      background: #fafafa;
    }
  }

  > strong {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #101010;
    margin-top: 24px;
  }

  > div {
    display: flex;
    align-items: center;
    margin-top: 20px;

    > span {
      font-size: 14px;
      color: #0d85ff;
      font-weight: 500;

      > svg {
        margin-right: 6px;
      }
    }

    > div {
      display: flex;
      /* border: 1px solid #ffb525; */
      margin-left: 6px;
      /* border-radius: 4px; */
      /* padding: 0 4px; */

      > span {
        display: flex;
        align-items: center;
        color: #cfcfcf;
        font-size: 13px;
      }
    }
  }
`;

export const DivFAQ = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding-bottom: 32px;

  > strong {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 16px;
  }

  > div {
    background: #fafafa;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #000000;
      margin-bottom: 4px;
    }

    > span {
      font-size: 13px;
      line-height: 19px;
      color: #838383;
    }
  }
`;

export const DivSubscriptionForms = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 12px;
  position: relative;
  /* box-shadow: 0px 8px 68px -10px rgba(0, 0, 0, 0.17); */
  padding: 32px;
  margin-top: 32px;
`;

export const DivHeaderPayment = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  > h2 {
    margin-left: 16px;
  }
`;

export const DivCards = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > div {
    & + div {
      margin-top: 8px;
    }
  }
`;

export const SelectButton = styled.button`
  position: absolute;
  right: 20px;
  border-radius: 8px;
  background: #0d85ff;
  padding: 8px 16px;
  border: none;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 12px;
  border: 0.5px solid #d8dee5;
  background: #fff;
  padding: 20px;

  > img {
    position: absolute;
    right: 20px;
  }
`;

export const DivImage = styled.div``;

export const DivBodyCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;

  > strong {
    color: #262626;
    font-size: 16px;
    font-weight: 600;
  }

  > span {
    color: #838383;
    font-size: 12px;
    font-weight: 400;
    margin-top: 4px;
  }

  .green {
    color: #06c86b;
    margin-top: 8px;
  }
`;

export const BodySecundaryCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > strong {
    color: #838383;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & + div {
      border-top: 1px solid #efefef;
      margin-top: 12px;
      padding-top: 12px;
    }

    > span {
      color: #838383;
      font-size: 12px;
      font-weight: 400;
      max-width: 180px;
    }

    .green {
      color: #06c86b;
    }
  }
`;

export const DivBolet = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;

  > button {
    display: flex;
    border: none;
    background: none;
    align-items: center;
    justify-content: center;
    color: #9d9d9d;
    font-size: 14px;
    font-weight: 600;

    > img {
      margin-right: 8px;
    }
  }
`;
