/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';
import { useHistory } from 'react-router-dom';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';

import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Label } from '~/designsystem/Fonts';

import {
  activatedFreightDesktopEvent,
  copyLinkBtnDesktopEvent,
  seePlansBottombarBannerDesktopEvent,
  seePlansSidebarBtnDesktopEvent,
  seeStoreBtnDesktopEvent,
} from '~/functions/events';

import OverduePayment from '~/components/OverduePayment';
import dataPlans from '../../../data/plans.json';

// import publicIp from 'public-ip';

import api from '../../../services/api';

import DragAndDropPointsIcon from '../../../assets/icons/dashboard/dragPoints.svg';
// import ArrowRightIcon from '../../../assets/icons/menu/arrowRightGradient.svg';
// import gridIcon from '../../../assets/icons/gridIcon.svg';
// import aboutIcon from '../../../assets/icons/aboutIcon.svg';

// import gridIconDisabled from '../../../assets/icons/gridIconDisabled.svg';
// import aboutIconDisabled from '../../../assets/icons/aboutIconDisabled.svg';

import plusIcon from '../../../assets/icons/plusIcon.svg';
import NoImage from '../../../assets/icons/noimage.png';

// import userIcon from '../../../assets/icons/userIcon.svg';
import LogoIcon from '../../../assets/icons/logo-text.svg';
import CopyIcon from '../../../assets/icons/copyIcon.svg';
import ViewIcon from '../../../assets/icons/viewIcon.svg';

import CatalogIcon from '../../../assets/icons/menu/desktop/catalog';
import ProfileIcon from '../../../assets/icons/menu/desktop/profile';
// import RequestsIcon from '../../../assets/icons/menu/desktop/requests';
import FreightIcon from '../../../assets/icons/menu/desktop/freight';
// import PlanIcon from '../../../assets/icons/menu/desktop/plan';
import InstagramIcon from '../../../assets/icons/menu/desktop/instagram';
import CardIcon from '../../../assets/icons/menu/desktop/card';
import CouponIcon from '../../../assets/icons/menu/desktop/coupon';
// import ConfigurationIcon from '../../../assets/icons/menu/desktop/configuration';
// import ConfigurationIconSvg from '../../../assets/icons/menu/desktop/configuration.svg';

import CancelIcon from '../../../assets/icons/cancelx-black.svg';
import CancelGreyIcon from '../../../assets/icons/cancelx.svg';

import InstagramGradientIcon from '../../../assets/icons/menu/desktop/instagram.svg';
import OrderIcon from '../../../assets/icons/order/order.svg';

import loader from '../../../assets/icons/loading-white.gif';
import Loader from '../../../assets/icons/loader-grey.gif';

import LinkList from './Linklist';
import TabPromotion from './TabPromotion';
import TabAbout from './TabAbout';
import MyAccount from './MyAccount';
import Settings from './Settings';
import MyPlan from './MyPlan';
import MyOrders from './MyOrders';
import Plans from './Plans';
import Freight from './Freight';
import PaymentMethods from './PaymentMethods';
import IntegrationWithInstagram from './Integrations/Instagram';
import Coupons from './Coupons';

// Configurations
import ConfigurationOrder from './Configurations/ConfigurationOrder';

import AddAddress from '../../../components/AddAddress';

import ProductModal from './ProductModal';
import TagModal from './TagModal';
import EditModal from './EditTagPanel';
import CancelModal from './ConfirmRemoveModal';
import ConfigurationModal from './ConfigurationModal';

import ProPlanModal from '../../../components/Popups/ProPlan';

import {
  ListTagsRequest,
  listPromotionRequest,
  listPaymentMethodsRequest,
  ListProductsPaginationRequest,
  UpdateOrderRequest,
  removeTagRequest,
  updateHasMoreSuccess,
  updatePageSuccess,
  clearStateInfiniteScrollSuccess,
} from '../../../store/modules/linkPrev/actions';

import { signOut } from '../../../store/modules/auth/actions';

import {
  Container,
  LabelMaintenanceMode,
  DivAvatar,
  DivLink,
  DivMenu,
  DivInviteFriends,
  Tab,
  DivBody,
  LinkContact,
  DivLeft,
  DivRight,
  DivProPlan,
  DivBodyRight,
  ButtonSeeMore,
  LabelPro,
  MenuItem,
  OrderNotification,
  DivInputSearch,
  StyledDebounceInput,
  DivTags,
  TagAdd,
  Tag,
  DivAddProduct,
  DivTabStock,
  DivStockProducts,
  DivStockProductsLeft,
  DivStockProductsRight,
  DivLinks,
  DivMyAccount,
  PromoNotification,
  AddVariation,
  DivAddVariation,
  DivItems,
  DivItem,
  DivPro,
  LabelPastDueSubscription,
  ButtonLinkStore,
  ButtonCopyToClipboard,
  // LabelPastDueSubscription,
} from './styles';
import MoreOptionsConfigurations from './MoreOptionsConfigurations';
import ConversionPixel from './ConversionPixel';
import Domain from './Integrations/Domain';

function Dashboard() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { boot } = useIntercom();

  const username = useSelector(
    state => state.user && state.user.profile && state.user.profile.username
  );

  const linkss = useSelector(state => state.linkPrev.links);
  const tags = useSelector(state => state.linkPrev.tags);

  const { cep, whatsapp } = useSelector(
    state => state.user && state.user.profile
  );
  const isLoading = useSelector(state => state.linkPrev.loadingRegisterProduct);

  const page = useSelector(state => state.linkPrev.page);
  const totalCount = useSelector(state => state.linkPrev.totalCount);
  const count = useSelector(state => state.linkPrev.count);
  const hasMore = useSelector(state => state.linkPrev.hasMore);

  const userId = useSelector(
    state => state.user && state.user.profile && state.user.profile.id
  );

  const [links, setLinks] = useState([]);
  const [pro, setPro] = useState();
  const [indexMenu, setIndexMenu] = useState(2);
  const [indexTab, setIndexTab] = useState(1);
  const [selectedItem, setSelectedItem] = useState(2);
  const [tagId, setTagId] = useState(0);
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [productId, setProductId] = useState(0);
  const [showEditTagModal, setShowEditTagModal] = useState(false);
  const [showTagModal, setShowTagModal] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);
  const [showProPlanPopUp, setShowProPlanPopUp] = useState(false);
  const [showConfigurationModal, setShowConfigurationModal] = useState(false);
  const [search, setSearch] = useState('');
  const [buttonFreightDisabled, setButtonFreightDisabled] = useState();
  const [orderNotification, setOrderNotification] = useState();
  const [userConfigurations, setUserConfigurations] = useState();
  const [subscription, setSubscription] = useState();

  const [masterId, setMasterId] = useState(null);
  const [indexSubCategory, setIndexSubCategory] = useState();

  const [showEditVariationModal, setShowEditVariationModal] = useState(false);
  const [showAddSubVariantModal, setShowAddSubVariantModal] = useState(false);
  const [showAddVariantModal, setShowAddVariantModal] = useState(false);
  const [attributes, setAttributes] = useState([]);
  const [variationId, setVariationId] = useState(0);

  const [domain, setDomain] = useState();

  const [tabAllProducts, setTabAllProducts] = useState(true);
  const [tabNoStock, setTabNoStock] = useState(false);
  const [noStockLinks, setNoStockLinks] = useState([]);

  // const [selectedItemsArray, setSelectedItemsArray] = useState([]);

  const [showAddAddressModal, setShowAddAddressModal] = useState(false);

  const [disableButtonSaveVariation, setDisableButtonSaveVariation] = useState(
    false
  );

  const [inputVariationName, setInputVariationName] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [environment, setEnvironment] = useState();

  // const [variationName, setVariationName] = useState('');
  const [newSubs, setNewSubs] = useState([]);

  const selectedColorMenu = '#101010';
  const notSelectedColorMenu = '#afafaf';

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const codepb = urlParams.get('codepb');
  const melhorEnvioParam = urlParams.get('state');

  async function LoadData() {
    // dispatch(ListLinkPreview());

    await api.get('/environment').then(res => {
      if (res && res.data) {
        setEnvironment(res.data);
      }
    });

    if (userId) {
      if (!whatsapp) {
        history.push('/responsivecustomizesocialmedia');
      }
    }

    dispatch(ListTagsRequest());
    dispatch(listPromotionRequest());
    dispatch(listPaymentMethodsRequest());

    await api.get('/domain').then(res => {
      if (res && res.data) {
        setDomain(res.data);
      }
    });

    await api.get('/proplan').then(response => {
      setPro(response.data);
      // window.history.replaceState(
      //   null,
      //   '',
      //   `/dashboard?subscriber=${response.data > 0 ? 1 : 0}`
      // );
    });

    await api.get('/userconfigurations').then(response => {
      setUserConfigurations(response.data);
    });

    await api.get('/orderrequestnotification').then(response => {
      setOrderNotification(response.data.length);
    });

    await api.get('/attributes').then(response => {
      setAttributes(response.data);
    });

    await api.get('/linksbystock').then(response => {
      setNoStockLinks(response.data);
    });

    if (code && melhorEnvioParam === 'melhorenvio') {
      setIndexMenu(5);
      setSelectedItem(5);
    }

    if ((code || codepb) && !melhorEnvioParam) {
      setIndexMenu(9);
      setSelectedItem(9);
    }

    // const ipv4 = await publicIp.v4();

    // await api.post(`/ip/${ipv4}`);
  }

  useEffect(() => {
    // Adicionado código <script> do zendesk no header da pagina  usando useEffect
    // const script = document.createElement('script');

    // script.id = 'ze-snippet';
    // script.src =
    //   'https://static.zdassets.com/ekr/snippet.js?key=caf36ef5-21d2-43e5-9a71-5a75aea659cc';
    // script.async = true;

    // document.body.appendChild(script);

    LoadData();
    boot();

    // return () => {
    //   document.body.removeChild(script);
    // };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLinks(linkss);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkss]);

  function handleLoadMoreProducts() {
    if (count === totalCount) {
      dispatch(updateHasMoreSuccess(false));

      return 0;
    }

    const currentPage = page;

    dispatch(
      ListProductsPaginationRequest(links, count, currentPage + 1, search, 0)
    );
    dispatch(updatePageSuccess(currentPage + 1));

    return 0;
  }

  function LoadProducts(hasSearch) {
    dispatch(clearStateInfiniteScrollSuccess());

    if (hasSearch) {
      dispatch(
        ListProductsPaginationRequest([], 0, 1, search.toLowerCase(), 0)
      );
    } else {
      dispatch(ListProductsPaginationRequest([], 0, 1, '', 0));
    }
  }

  useEffect(() => {
    // if (search.length > 0) {
    LoadProducts(true);
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  function handleClickProduct(id) {
    setProductId(id);
    setShowProductModal(!showProductModal);
  }

  async function handleDelete(id) {
    try {
      await api.post(`/links/${id}`);

      if (search.length > 0) {
        setSearch('');
      } else {
        LoadProducts(false);
      }

      toast.success('Produto removido');
    } catch (error) {
      toast.error('Não foi possível remover o link, verifique seus dados');
    }

    LoadData();
  }

  async function handleBlur(id, link) {
    try {
      if (link && link.link) {
        if (link.link.indexOf('http') === -1) {
          link.link = `http://${link.link}`;
        }
      }

      await api.put(`/links/${id}`, link);
    } catch (error) {
      toast.error('Não foi possível alterar o link, verifique seus dados');
    }

    LoadData();
  }

  // function SearchLink() {
  //   const linksFiltered =
  //     linkss &&
  //     linkss.filter(link =>
  //       link.title.toLowerCase().includes(search.toLowerCase())
  //     );

  //   setLinks(linksFiltered);
  // }

  // useEffect(() => {
  //   SearchLink();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [search]);

  async function handleClickDelete(id) {
    setShowTagModal(false);
    dispatch(removeTagRequest(id));

    if (masterId) {
      const array = [...subCategories];
      const findIndex = array[indexSubCategory].findIndex(arr => arr.id === id);

      if (findIndex > -1) {
        // Verifica se existe alguma subcategoria aberta abaixo da que está sendo deletada

        if (array.length > indexSubCategory + 1) {
          // Verifica se a subcategoria de baixo é descendente da categoria que está sendo deleteda
          if (
            array[indexSubCategory][findIndex].id ===
            array[indexSubCategory + 1][0].master_id
          ) {
            array.splice(indexSubCategory + 1, array.length);
          }
        }

        array[indexSubCategory].splice(findIndex, 1);

        setSubCategories(array);
      }
    } else {
      setSubCategories([]);
    }
  }

  async function handleClickDeleteVariation(id, canceled) {
    setShowEditVariationModal(false);

    if (canceled) {
      await api.delete(`/attributes/${id}`);

      const array = attributes && attributes.filter(att => att.id !== id);

      setAttributes(array);

      closeModalAddVariation();
    }
  }

  function tagModal(modal) {
    setTagId(0);
    setShowTagModal(modal);
  }

  function productModal(modal) {
    setShowProductModal(modal);
  }

  function editTagModal(modal) {
    setShowEditTagModal(modal);
  }

  function ProPlanPopUp(pro_modal) {
    setShowProPlanPopUp(pro_modal);
  }

  // function editAttributeModal(modal) {
  //   setShowEditVariationModal(modal);
  // }

  function handleClickTagModal(id) {
    // setDivTagId('');
    setTagId(id);
    setShowEditTagModal(false);

    setTimeout(() => {
      setShowTagModal(!showTagModal);
    }, 500);
  }

  function handleClickEditTagModal(id, master_id, index) {
    setTagId(id);

    if (master_id) {
      setMasterId(master_id);
      setIndexSubCategory(index);
    }

    setTimeout(() => {
      setShowTagModal(!showTagModal);
      // setShowEditTagModal(!showEditTagModal);
    }, 500);
  }

  async function handleClickVariationModal(id) {
    // setDivTagId('');
    // setVariationId(id);

    await api.get(`/attributesbyid/${id}`).then(response => {
      setVariationId(response.data.id);
      setInputVariationName(response.data.name);
    });

    await api.get(`/subattributes/${id}`).then(response => {
      const arr = [...response.data];

      setNewSubs(arr);
    });

    setShowEditVariationModal(false);

    // setTimeout(() => {
    setShowAddSubVariantModal(!showAddSubVariantModal);
    // }, 300);
  }

  // function handleClickEditVariationModal(id) {
  //   setVariationId(id);

  //   setTimeout(() => {
  //     setShowEditVariationModal(!showEditVariationModal);
  //   }, 500);
  // }

  // costante criada para remover a tag "Todos" da seleção
  const tagNoAll = useMemo(
    () => tags && tags.filter(tag => tag.tag !== 'Todos'),
    [tags]
  );

  function handleSignOut() {
    dispatch(signOut());
  }

  async function handleCreateTableFlatRate() {
    try {
      const existsFreight = await api.get('/configfreight');

      if (existsFreight && existsFreight.data) {
      } else {
        await api.post('/createfreight');
      }

      const er = await api.get('/existsregion');

      if (er && er.data && er.data.length > 0) {
        setSelectedItem(5);
        setIndexMenu(5);
      } else {
        // setButtonFreightDisabled(true);
        // const region1 = await api.post('/userregion', { regionId: 1 });
        // // Criando a região Norte
        // await api.post('/createuseruf', {
        //   ufId: 1,
        //   userRegionid: region1.data.id,
        // });
        // await api.post('/createuseruf', {
        //   ufId: 2,
        //   userRegionid: region1.data.id,
        // });
        // await api.post('/createuseruf', {
        //   ufId: 3,
        //   userRegionid: region1.data.id,
        // });
        // await api.post('/createuseruf', {
        //   ufId: 4,
        //   userRegionid: region1.data.id,
        // });
        // await api.post('/createuseruf', {
        //   ufId: 5,
        //   userRegionid: region1.data.id,
        // });
        // await api.post('/createuseruf', {
        //   ufId: 6,
        //   userRegionid: region1.data.id,
        // });
        // await api.post('/createuseruf', {
        //   ufId: 7,
        //   userRegionid: region1.data.id,
        // });
        // const region2 = await api.post('/userregion', { regionId: 2 });
        // // Criando a região Centro Oeste
        // await api.post('/createuseruf', {
        //   ufId: 8,
        //   userRegionid: region2.data.id,
        // });
        // await api.post('/createuseruf', {
        //   ufId: 9,
        //   userRegionid: region2.data.id,
        // });
        // await api.post('/createuseruf', {
        //   ufId: 10,
        //   userRegionid: region2.data.id,
        // });
        // await api.post('/createuseruf', {
        //   ufId: 11,
        //   userRegionid: region2.data.id,
        // });
        // const region3 = await api.post('/userregion', { regionId: 3 });
        // // Criando a região Nordeste
        // await api.post('/createuseruf', {
        //   ufId: 12,
        //   userRegionid: region3.data.id,
        // });
        // await api.post('/createuseruf', {
        //   ufId: 13,
        //   userRegionid: region3.data.id,
        // });
        // await api.post('/createuseruf', {
        //   ufId: 14,
        //   userRegionid: region3.data.id,
        // });
        // await api.post('/createuseruf', {
        //   ufId: 15,
        //   userRegionid: region3.data.id,
        // });
        // await api.post('/createuseruf', {
        //   ufId: 16,
        //   userRegionid: region3.data.id,
        // });
        // await api.post('/createuseruf', {
        //   ufId: 17,
        //   userRegionid: region3.data.id,
        // });
        // await api.post('/createuseruf', {
        //   ufId: 18,
        //   userRegionid: region3.data.id,
        // });
        // await api.post('/createuseruf', {
        //   ufId: 19,
        //   userRegionid: region3.data.id,
        // });
        // await api.post('/createuseruf', {
        //   ufId: 20,
        //   userRegionid: region3.data.id,
        // });
        // const region4 = await api.post('/userregion', { regionId: 4 });
        // // Criando a região Sudeste
        // await api.post('/createuseruf', {
        //   ufId: 21,
        //   userRegionid: region4.data.id,
        // });
        // await api.post('/createuseruf', {
        //   ufId: 22,
        //   userRegionid: region4.data.id,
        // });
        // await api.post('/createuseruf', {
        //   ufId: 23,
        //   userRegionid: region4.data.id,
        // });
        // await api.post('/createuseruf', {
        //   ufId: 24,
        //   userRegionid: region4.data.id,
        // });
        // const region5 = await api.post('/userregion', { regionId: 5 });
        // // Criando a região Sul
        // await api.post('/createuseruf', {
        //   ufId: 25,
        //   userRegionid: region5.data.id,
        // });
        // await api.post('/createuseruf', {
        //   ufId: 26,
        //   userRegionid: region5.data.id,
        // });
        // await api.post('/createuseruf', {
        //   ufId: 27,
        //   userRegionid: region5.data.id,
        // });
        // setButtonFreightDisabled(false);
        setSelectedItem(5);
        setIndexMenu(5);
      }
    } catch (err) {
      setButtonFreightDisabled(false);
      toast.error('Ocorreu um erro ao habilitar sistema de frete');
    }
  }

  async function loadAttributes() {
    await api.get('attributeswithsubs').then(response => {
      setAttributes(response.data);
    });

    // if (productId > 0) {
    //   await api
    //     .get(`subattributesselecteditems/${productId}`)
    //     .then(response => {
    //       setSelectedItemsArray(response.data);
    //     });
    // }
  }

  useEffect(() => {
    loadAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  useEffect(() => {
    async function load() {
      await api.get('subscription').then(response => {
        setSubscription(response.data);
      });
    }

    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  function handleClickEditVariationModal(id) {
    setVariationId(id);

    setTimeout(() => {
      setShowEditVariationModal(!showEditVariationModal);
    }, 500);
  }

  function clearFields() {
    setNewSubs([
      {
        id: Math.floor(Math.random() * 1000) + 1,
        name: '',
        attr_id: 0,
        isNew: true,
      },
    ]);
    setInputVariationName('');
    setVariationId(0);
  }

  function closeModalAddVariation() {
    setShowAddVariantModal(false);
    setShowAddSubVariantModal(false);
    clearFields();
  }

  function handleInsertNewSub() {
    const existsEmptyName = newSubs.filter(n => n.name === '');

    if (existsEmptyName.length === 0) {
      setNewSubs([
        {
          id: Math.floor(Math.random() * 1000) + 1,
          name: '',
          attr_id: 0,
          isNew: true,
        },
        ...newSubs,
      ]);
    }
  }

  async function handleDeleteSub(sub, index) {
    if (sub.id > 0) {
      await api.delete(`/subattributes/${sub.id}`);
    }

    const array = newSubs.filter((ns, ind) => ind !== index);

    setNewSubs(array);
  }

  function handleBlurSub(sub, index, text) {
    const array = [...newSubs];

    const existsName = newSubs.filter(
      (ns, ind) => ns.name === text && ind !== index
    );

    if (existsName.length > 0) {
      handleDeleteSub(sub, index);
    } else {
      array[index].name = text;

      setNewSubs(array);
    }
  }

  async function handleSubmitVariation() {
    try {
      setDisableButtonSaveVariation(true);

      if (!inputVariationName) {
        toast.error('Preencha o nome da variação');
        setDisableButtonSaveVariation(false);
        return 0;
      }

      const attribute = await api.post('/attributes', {
        name: inputVariationName,
      });

      if (attribute && attribute.data) {
        await Promise.all(
          newSubs &&
            newSubs.map(async ns => {
              if (ns.name) {
                await api.post('/subattributes', {
                  name: ns.name,
                  attribute_id: attribute.data.id,
                });
              }
            })
        );

        setAttributes([...attributes, attribute.data]);
      }

      closeModalAddVariation();
      loadAttributes();
      setDisableButtonSaveVariation(false);
      return 0;
    } catch (err) {
      setDisableButtonSaveVariation(false);
      return 0;
    }
  }

  function updateAttributes(name) {
    const array = [...attributes];

    const findIndex = array.findIndex(attr => attr.id === variationId);

    if (findIndex >= 0) {
      array[findIndex].name = name;

      setAttributes(array);
    }
  }

  async function handleSubmitEditVariation() {
    try {
      setDisableButtonSaveVariation(true);
      if (!inputVariationName) {
        toast.error('Preencha o nome da variação');
        setDisableButtonSaveVariation(false);
        return 0;
      }

      const attribute = await api.put(`/attributes/${variationId}`, {
        name: inputVariationName,
      });

      if (attribute && attribute.data) {
        await Promise.all(
          newSubs &&
            newSubs.map(async (ns, index) => {
              if (ns.name) {
                if (ns.isNew) {
                  await api.post('/subattributes', {
                    name: ns.name,
                    attribute_id: variationId,
                    order: index,
                  });
                } else {
                  await api.put(`/subattributes/${ns.id}`, {
                    name: ns.name,
                    order: index,
                  });
                }
              }
            })
        );

        // setAttributes([...attributes, attribute.data]);
      }

      updateAttributes(inputVariationName);
      setDisableButtonSaveVariation(false);
      closeModalAddVariation();

      return 0;
    } catch (err) {
      setDisableButtonSaveVariation(false);
      return 0;
    }
  }

  // parâmetro onSubmit para saber se o retorno do modal foi Ok ou cancel
  async function handleSetShowAddAddress(value, onSubmit) {
    if (cep) {
      handleCreateTableFlatRate();
    } else {
      activatedFreightDesktopEvent();
      setShowAddAddressModal(value);

      if (onSubmit) {
        handleCreateTableFlatRate();
      }
    }
  }

  async function refreshIndex(linksOrdened) {
    // await api.put('/order', linksOrdened);
    dispatch(UpdateOrderRequest(linksOrdened));
    // dispatch(UpdateOrderSuccess(linksOrdened));
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const linksReordered = reorder(
      links,
      result.source.index,
      result.destination.index
    );

    setLinks(linksReordered);
    refreshIndex(linksReordered);
  }

  async function handleSearchSubCategories(id, isSub, index) {
    if (
      subCategories &&
      subCategories[0] &&
      subCategories[0][0] &&
      subCategories[0][0].master_id === id
    ) {
      setSubCategories([]);
      return 0;
    }

    if (
      isSub &&
      subCategories &&
      subCategories[index + 1] &&
      subCategories[index + 1][0] &&
      subCategories[index + 1][0].master_id === id
    ) {
      const array = [...subCategories];
      const newArray = array.slice(0, index + 1);
      setSubCategories(newArray);
      return 0;
    }

    await api
      .post(`/subcategoriesbytag/${id}`, { user_id: userId })
      .then(response => {
        if (response.data) {
          if (isSub) {
            const array = [...subCategories];
            const newArray = array.slice(0, index + 1);

            newArray.push(response.data);
            setSubCategories(newArray);
          } else {
            const array = [];
            array.push(response.data);
            setSubCategories(array);
          }
        }
      });

    return 0;
  }

  function updateTagsComponent(master_id) {
    if (master_id) {
      let index;
      let isSub = false;

      for (let i = 0; i < subCategories.length; i++) {
        if (subCategories[i].find(c => c.id === master_id)) {
          index = i;
          isSub = true;

          break;
        }
      }

      if (subCategories && subCategories[0] && subCategories[0].length > 0) {
        handleSearchSubCategories(master_id, isSub, index || null);
      }
    }

    dispatch(ListTagsRequest());
  }

  function onDragEndVariations(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const VariationsReordered = reorder(
      newSubs,
      result.source.index,
      result.destination.index
    );

    setNewSubs(VariationsReordered);
  }

  // variavel index guarda o valor do menu para mudar de tela
  function SetShowConfigurationModal(index) {
    setShowConfigurationModal(false);

    if (index > 0) {
      setSelectedItem(index);
      setIndexMenu(index);
    }
  }

  function SetIndexMenu(index) {
    setIndexMenu(index || 0);
  }

  function revalidateStock() {
    const stock =
      noStockLinks && noStockLinks.filter(s => s.stock_quantity === 0);

    setNoStockLinks(stock);
  }

  async function handleAlterStock(productid, amount, variationid, index) {
    // dispatch(updateStockRequest(productId, amount, variationid));

    const array = [...noStockLinks];
    array[index].stock_quantity = amount >= 0 ? amount : 0;
    setNoStockLinks(array);

    await api.put(`linksfield/${productid}`, {
      stock_quantity: amount,
      variationId: variationid,
    });
  }

  return (
    <>
      <ProductModal
        showModal={showProductModal}
        Modal={productModal}
        id={productId}
        setProductId={setProductId}
        userId={userId}
      />
      <ProPlanModal showModal={showProPlanPopUp} Modal={ProPlanPopUp} />
      <TagModal
        showModal={showTagModal}
        Modal={tagModal}
        updateTagsComponent={updateTagsComponent}
        handleClickDelete={handleClickDelete}
        TagId={tagId}
        pro={pro}
      />
      <EditModal
        showModal={showEditTagModal}
        Modal={editTagModal}
        handleClickTagModal={handleClickTagModal}
        handleClickDelete={handleClickDelete}
        id={tagId}
        label="categoria"
      />
      {/* <EditModal
        showModal={showEditVariationModal}
        Modal={editAttributeModal}
        handleClickTagModal={handleClickVariationModal}
        handleClickDelete={handleClickDeleteVariation}
        id={variationId}
        label="variação"
      /> */}
      <CancelModal
        showModal={showEditVariationModal}
        Modal={handleClickDeleteVariation}
        id={variationId}
      />

      {/* {subscription && subscription.payment_status === 'past_due' && (
        <LabelPastDueSubscription>
          <b>Sua assinatura está vencida</b>, pois seu último pagamento falhou,
          atualize sua forma de pagamento
        </LabelPastDueSubscription>
      )} */}

      <Container environment={environment}>
        <header>
          <DivAvatar>
            <img
              src={LogoIcon}
              alt="logo"
              onClick={() => {
                setSelectedItem(2);
                setIndexMenu(2);
              }}
            />
          </DivAvatar>

          <DivLink>
            <span>Seu link:</span>

            <input
              type="text"
              readOnly
              value={`${
                domain && domain.domain && domain.active
                  ? domain.domain
                  : `${username}.offstore.me`
              }`}
            />

            <CopyToClipboard
              text={`${
                domain && domain.domain && domain.active
                  ? domain.domain
                  : `${username}.offstore.me`
              }`}
            >
              <ButtonCopyToClipboard
                type="button"
                onClick={() => {
                  copyLinkBtnDesktopEvent();
                  toast.success('Link copiado');
                }}
              >
                <img src={CopyIcon} alt="copy" />
              </ButtonCopyToClipboard>
            </CopyToClipboard>

            {/* <a
              href={`${
                domain && domain.domain && domain.active
                  ? `https://${domain.domain}`
                  : `https://${username}.offstore.me`
              }`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ViewIcon} alt="view" />
            </a> */}

            <ButtonLinkStore
              type="button"
              onClick={() => {
                seeStoreBtnDesktopEvent();

                if (domain && domain.domain && domain.active) {
                  window.open(`https://${domain.domain}`, '_blank');
                  return 0;
                }

                window.open(`https://${username}.offstore.me`, '_blank');
                return 0;
              }}
            >
              <img src={ViewIcon} alt="view" />
            </ButtonLinkStore>

            <DivMyAccount>
              <div
                onClick={() => {
                  setSelectedItem(4);
                  setIndexMenu(4);
                }}
              >
                <img src={OrderIcon} alt="order" />

                <span>Pedidos</span>

                {orderNotification > 0 && (
                  <PromoNotification color="#FF6C4B">
                    <span>{orderNotification}</span>
                  </PromoNotification>
                )}
              </div>
            </DivMyAccount>

            {/* <DivMyAccount>
              <div
                onClick={() => {
                  setShowConfigurationModal(true);
                }}
              >
                <img src={ConfigurationIconSvg} alt="order" />
              </div>
            </DivMyAccount> */}
          </DivLink>
        </header>

        <DivBody>
          <DivLeft>
            <div>
              <MenuItem
                className={selectedItem === 2 ? 'selected' : ''}
                onClick={() => {
                  setSelectedItem(2);
                  setIndexMenu(2);
                }}
                color="#000"
              >
                <div>
                  <CatalogIcon
                    color={
                      selectedItem === 2
                        ? selectedColorMenu
                        : notSelectedColorMenu
                    }
                  />
                </div>

                <span>Catálogo</span>
              </MenuItem>

              {/* <MenuItem
                className={selectedItem === 4 ? 'selected' : ''}
                onClick={() => {
                  setSelectedItem(4);
                  setIndexMenu(4);
                }}
                color="#000"
              >
                <div>
                  <RequestsIcon
                    color={
                      selectedItem === 4
                        ? selectedColorMenu
                        : notSelectedColorMenu
                    }
                  />
                </div>

                <span>Pedidos</span>

                {orderNotification > 0 && (
                  <OrderNotification>
                    <span />
                  </OrderNotification>
                )}
              </MenuItem> */}

              <MenuItem
                className={selectedItem === 3 ? 'selected' : ''}
                onClick={() => {
                  setSelectedItem(3);
                  setIndexMenu(3);
                }}
                color="#000"
              >
                <div>
                  <ProfileIcon
                    color={
                      selectedItem === 3
                        ? selectedColorMenu
                        : notSelectedColorMenu
                    }
                  />
                </div>

                <span>Personalizar</span>
              </MenuItem>

              <MenuItem
                className={selectedItem === 5 ? 'selected' : ''}
                onClick={() => handleSetShowAddAddress(true, false)}
                color="#000"
                disabled={buttonFreightDisabled}
              >
                <div>
                  <FreightIcon
                    color={
                      selectedItem === 5
                        ? selectedColorMenu
                        : notSelectedColorMenu
                    }
                  />
                </div>

                <span>Editar frete</span>

                {/* <span className="span-new">Novo</span> */}
              </MenuItem>

              <MenuItem
                className={selectedItem === 9 ? 'selected' : ''}
                onClick={() => {
                  setSelectedItem(9);
                  setIndexMenu(9);
                }}
                color="#000"
              >
                <div>
                  <CardIcon
                    color={
                      selectedItem === 9
                        ? selectedColorMenu
                        : notSelectedColorMenu
                    }
                  />
                </div>

                <span>Forma de pagamento</span>
                {pro === 0 && <Label>PRO</Label>}
              </MenuItem>

              <MenuItem
                className={selectedItem === 10 ? 'selected' : ''}
                onClick={() => {
                  setSelectedItem(10);
                  setIndexMenu(10);
                }}
                color="#000"
              >
                <div>
                  <CouponIcon
                    color={
                      selectedItem === 10
                        ? selectedColorMenu
                        : notSelectedColorMenu
                    }
                  />
                </div>

                <span>Cupons</span>
                {pro === 0 && <Label>PRO</Label>}
              </MenuItem>

              <MenuItem
                className={selectedItem === 8 ? 'selected' : ''}
                onClick={() => {
                  setSelectedItem(8);
                  setIndexMenu(8);
                }}
                color="#000"
                // disabled
              >
                <div>
                  <InstagramIcon
                    color={
                      selectedItem === 8
                        ? selectedColorMenu
                        : notSelectedColorMenu
                    }
                  />
                </div>

                <span>Vincular ao Instagram</span>
                {pro === 0 && <Label>PRO</Label>}
              </MenuItem>

              <MenuItem
                className={selectedItem === 12 ? 'selected' : ''}
                onClick={() => {
                  setSelectedItem(12);
                  setIndexMenu(12);
                }}
                color="#000"
              >
                <span className="span-link">Mais opções</span>
              </MenuItem>

              {/* <MenuItem
                className={selectedItem === 6 ? 'selected' : ''}
                onClick={() => {
                  setSelectedItem(6);
                  setIndexMenu(6);
                }}
                color="#000"
              >
                <div>
                  <PlanIcon
                    color={
                      selectedItem === 6
                        ? selectedColorMenu
                        : notSelectedColorMenu
                    }
                  />
                </div>

                <span>Meu plano</span>
              </MenuItem> */}

              {/* <MenuItem
                className={selectedItem === 6 ? 'selected' : ''}
                onClick={() => {
                  setShowConfigurationModal(true);
                }}
                color="#000"
              >
                <div>
                  <ConfigurationIcon
                    color={
                      selectedItem === 6
                        ? selectedColorMenu
                        : notSelectedColorMenu
                    }
                  />
                </div>

                <span>Configurações</span>
              </MenuItem> */}

              {pro === 0 && (
                <DivPro>
                  <DivInviteFriends
                    onClick={() => {
                      seePlansSidebarBtnDesktopEvent();
                      setIndexMenu(7);
                      setSelectedItem();
                    }}
                  >
                    <span>Assinar plano {dataPlans.pro.name}</span>
                    {/* <div className="off">
                    <span>50% OFF</span>
                  </div> */}

                    <svg
                      width="18"
                      height="12"
                      viewBox="0 0 18 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.999726 6L16.7627 6"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.0222 11L17 6L12.0222 1"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </DivInviteFriends>

                  <span>
                    Assine com {dataPlans.pro.price.yearly.economyPercent} de
                    desconto
                  </span>
                </DivPro>
              )}
            </div>

            <LinkContact
              href="https://www.instagram.com/offstore.me/"
              target="_blank"
              className="button-exit"
            >
              Entrar em contato
              <img src={InstagramGradientIcon} alt="instagram" />
            </LinkContact>
          </DivLeft>

          <DivRight>
            {indexMenu === 2 ? (
              <>
                {/* <DivMenu>
                  <ul>
                    <li>
                      <Tab
                        className={indexTab === 1 ? 'selected' : ''}
                        onClick={() => setIndexTab(1)}
                      >
                        <img
                          src={indexTab === 1 ? gridIcon : gridIconDisabled}
                          alt="grid-icon"
                        />
                      </Tab>
                    </li>
                    <li>
                      <Tab
                        className={indexTab === 3 ? 'selected' : ''}
                        onClick={() => setIndexTab(3)}
                      >
                        <img
                          src={indexTab === 3 ? aboutIcon : aboutIconDisabled}
                          alt="about-icon"
                        />
                      </Tab>
                    </li>
                  </ul>
                </DivMenu> */}
                <DivBodyRight
                  fixed={
                    showTagModal ||
                    showAddVariantModal ||
                    showAddSubVariantModal
                  }
                >
                  {indexTab === 1 ? (
                    <>
                      <DivInputSearch>
                        {/* <input type="text" placeholder="Buscar produto" /> */}
                        <StyledDebounceInput
                          placeholder="Buscar produto"
                          minLength={1}
                          debounceTimeout={500}
                          onChange={e => setSearch(e.target.value)}
                        />
                      </DivInputSearch>

                      <DivTags>
                        <span>Categorias</span>
                        <div className="div-tags">
                          <TagAdd onClick={() => handleClickTagModal()}>
                            <img src={plusIcon} alt="plus" />
                          </TagAdd>
                          {tagNoAll &&
                            tagNoAll.map(tag => (
                              <div className="div-internal-tag" key={tag.id}>
                                <Tag
                                  key={tag.id}
                                  selected={
                                    !!(
                                      subCategories &&
                                      subCategories[0] &&
                                      subCategories[0][0] &&
                                      subCategories[0][0].master_id === tag.id
                                    )
                                  }
                                >
                                  <div
                                    className="body-tag"
                                    onClick={() =>
                                      handleSearchSubCategories(
                                        tag.id,
                                        false,
                                        null
                                      )
                                    }
                                  >
                                    {tag.tag}
                                  </div>
                                  <div
                                    onClick={() =>
                                      handleClickEditTagModal(
                                        tag.id,
                                        null,
                                        null
                                      )
                                    }
                                  >
                                    <svg
                                      width="13"
                                      height="13"
                                      viewBox="0 0 13 13"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M9.32139 1.45958C9.46709 1.31387 9.64007 1.19829 9.83044 1.11944C10.0208 1.04059 10.2248 1 10.4309 1C10.637 1 10.841 1.04059 11.0314 1.11944C11.2217 1.19829 11.3947 1.31387 11.5404 1.45958C11.6861 1.60528 11.8017 1.77826 11.8806 1.96863C11.9594 2.159 12 2.36304 12 2.5691C12 2.77515 11.9594 2.97919 11.8806 3.16956C11.8017 3.35993 11.6861 3.53291 11.5404 3.67861L4.05117 11.1679L1 12L1.83214 8.94883L9.32139 1.45958Z"
                                        stroke={
                                          subCategories &&
                                          subCategories[0] &&
                                          subCategories[0][0] &&
                                          subCategories[0][0].master_id ===
                                            tag.id
                                            ? '#FFFFFF'
                                            : '#9D9D9D'
                                        }
                                        strokeWidth="1.25"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </div>
                                </Tag>
                              </div>
                            ))}
                        </div>
                      </DivTags>

                      {subCategories &&
                        subCategories[0] &&
                        subCategories[0].length > 0 && (
                          <>
                            {subCategories.map((categories, index) => (
                              <>
                                {categories && categories[0] && (
                                  <DivTags>
                                    <span className="subcategorias">
                                      Subcategorias em "
                                      {categories[0].master.value}"{' '}
                                    </span>
                                    <div className="div-tags">
                                      {categories.map((tag, tagIndex) => (
                                        <div
                                          className="div-internal-tag"
                                          key={tag.id}
                                        >
                                          <Tag
                                            key={tag.id}
                                            selected={
                                              !!(
                                                subCategories &&
                                                subCategories[index + 1] &&
                                                subCategories[index + 1][0] &&
                                                subCategories[index + 1][0]
                                                  .master_id === tag.id
                                              )
                                            }
                                            index={tagIndex}
                                          >
                                            <div
                                              className="body-tag"
                                              onClick={() =>
                                                handleSearchSubCategories(
                                                  tag.id,
                                                  true,
                                                  index
                                                )
                                              }
                                            >
                                              {tag.tag}
                                            </div>

                                            <div
                                              onClick={() =>
                                                handleClickEditTagModal(
                                                  tag.id,
                                                  tag.master_id,
                                                  index
                                                )
                                              }
                                            >
                                              <svg
                                                width="13"
                                                height="13"
                                                viewBox="0 0 13 13"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M9.32139 1.45958C9.46709 1.31387 9.64007 1.19829 9.83044 1.11944C10.0208 1.04059 10.2248 1 10.4309 1C10.637 1 10.841 1.04059 11.0314 1.11944C11.2217 1.19829 11.3947 1.31387 11.5404 1.45958C11.6861 1.60528 11.8017 1.77826 11.8806 1.96863C11.9594 2.159 12 2.36304 12 2.5691C12 2.77515 11.9594 2.97919 11.8806 3.16956C11.8017 3.35993 11.6861 3.53291 11.5404 3.67861L4.05117 11.1679L1 12L1.83214 8.94883L9.32139 1.45958Z"
                                                  stroke={
                                                    subCategories &&
                                                    subCategories[index + 1] &&
                                                    subCategories[
                                                      index + 1
                                                    ][0] &&
                                                    subCategories[index + 1][0]
                                                      .master_id === tag.id
                                                      ? '#FFFFFF'
                                                      : '#9D9D9D'
                                                  }
                                                  strokeWidth="1.25"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                              </svg>
                                            </div>
                                          </Tag>
                                        </div>
                                      ))}
                                    </div>
                                  </DivTags>
                                )}
                              </>
                            ))}
                          </>
                        )}

                      {showSeeMore && (
                        <DivTags>
                          <div>
                            <span>Variações</span>

                            {pro === 0 && (
                              <LabelPro>
                                <span>PRO</span>
                              </LabelPro>
                            )}
                          </div>

                          <div className="div-tags">
                            <TagAdd
                              onClick={
                                pro >= 1
                                  ? () => setShowAddVariantModal(true)
                                  : () => setShowProPlanPopUp(true)
                              }
                            >
                              <img src={plusIcon} alt="plus" />
                            </TagAdd>

                            {attributes &&
                              attributes.map(att => (
                                <div className="div-internal-tag" key={att.id}>
                                  <Tag>
                                    <div>{att.name}</div>

                                    <div
                                      className="icons-variations"
                                      onClick={
                                        pro >= 1
                                          ? () =>
                                              handleClickVariationModal(att.id)
                                          : () => setShowProPlanPopUp(true)
                                      }
                                    >
                                      <svg
                                        width="13"
                                        height="13"
                                        viewBox="0 0 13 13"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M9.32139 1.45958C9.46709 1.31387 9.64007 1.19829 9.83044 1.11944C10.0208 1.04059 10.2248 1 10.4309 1C10.637 1 10.841 1.04059 11.0314 1.11944C11.2217 1.19829 11.3947 1.31387 11.5404 1.45958C11.6861 1.60528 11.8017 1.77826 11.8806 1.96863C11.9594 2.159 12 2.36304 12 2.5691C12 2.77515 11.9594 2.97919 11.8806 3.16956C11.8017 3.35993 11.6861 3.53291 11.5404 3.67861L4.05117 11.1679L1 12L1.83214 8.94883L9.32139 1.45958Z"
                                          stroke="#9D9D9D"
                                          strokeWidth="1.25"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                      {/* <img
                                        src={PenIcon}
                                        alt="pen"
                                        onClick={
                                          pro >= 1
                                            ? () =>
                                                handleClickVariationModal(
                                                  att.id
                                                )
                                            : () => setShowProPlanPopUp(true)
                                        }
                                      /> */}
                                      {/* <img
                                        src={TrashIcon}
                                        alt="trash"
                                        onClick={
                                          pro >= 1
                                            ? () =>
                                                handleClickEditVariationModal(
                                                  att.id
                                                )
                                            : () => setShowProPlanPopUp(true)
                                        }
                                      /> */}
                                    </div>
                                  </Tag>
                                </div>
                              ))}
                          </div>
                        </DivTags>
                      )}

                      {(showSeeMore && (
                        <ButtonSeeMore
                          type="button"
                          onClick={() => setShowSeeMore(false)}
                        >
                          Menos opções
                        </ButtonSeeMore>
                      )) || (
                        <ButtonSeeMore
                          type="button"
                          onClick={() => setShowSeeMore(true)}
                        >
                          Mais opções
                        </ButtonSeeMore>
                      )}

                      <DivAddProduct>
                        <span>Produtos</span>

                        <button
                          type="button"
                          onClick={() => {
                            handleClickProduct(0);
                          }}
                          disabled={isLoading}
                        >
                          {(isLoading && <img src={loader} alt="" />) ||
                            'Adicionar produto'}
                        </button>
                      </DivAddProduct>

                      {noStockLinks && noStockLinks.length > 0 && (
                        <DivTabStock>
                          <div
                            onClick={() => {
                              setTabAllProducts(true);
                              setTabNoStock(false);
                              revalidateStock();
                            }}
                          >
                            <strong className={tabAllProducts && 'active'}>
                              Todos os produtos
                            </strong>
                          </div>
                          <div
                            onClick={() => {
                              setTabNoStock(true);
                              setTabAllProducts(false);
                            }}
                          >
                            <strong className={tabNoStock && 'active'}>
                              Sem estoque
                            </strong>

                            {noStockLinks && noStockLinks.length > 0 && (
                              <div className="notification">
                                <strong>{noStockLinks.length}</strong>
                              </div>
                            )}
                          </div>
                        </DivTabStock>
                      )}

                      {(tabAllProducts && (
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                              <DivLinks
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                <InfiniteScroll
                                  dataLength={count} // This is important field to render the next data
                                  next={handleLoadMoreProducts}
                                  hasMore={hasMore}
                                  loader={
                                    <div className="loader">
                                      <img src={Loader} alt="loader" />
                                    </div>
                                  }
                                >
                                  {links &&
                                    links.map((linkk, index) => (
                                      <Draggable
                                        key={String(linkk.id)}
                                        draggableId={String(linkk.id)}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className="div-linklist"
                                          >
                                            <LinkList
                                              key={String(linkk.id)}
                                              linkk={linkk}
                                              deleteLink={handleDelete}
                                              Blur={handleBlur}
                                              index={index}
                                              handleClickModal={
                                                handleClickProduct
                                              }
                                            />
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                  {provided.placeholder}
                                </InfiniteScroll>
                              </DivLinks>
                            )}
                          </Droppable>
                        </DragDropContext>
                      )) || (
                        <DivStockProducts>
                          {noStockLinks &&
                            noStockLinks.map((p, index) => (
                              <div key={p.key}>
                                <img
                                  src={
                                    (p.images &&
                                      p.images[0] &&
                                      p.images[0].image_url) ||
                                    (p.Link &&
                                      p.Link.images &&
                                      p.Link.images[0] &&
                                      p.Link.images[0].image_url) ||
                                    NoImage
                                  }
                                  alt="logo"
                                />
                                <DivStockProductsLeft
                                  onClick={() => handleClickProduct(p.id)}
                                >
                                  <span>{p.title || p.Link.title}</span>

                                  <div>
                                    {p.label ? (
                                      <strong>{p.label}</strong>
                                    ) : (
                                      <strong>
                                        {p.promotion_price || p.price}
                                      </strong>
                                    )}
                                  </div>
                                </DivStockProductsLeft>

                                <DivStockProductsRight>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleAlterStock(
                                        p.id,
                                        parseInt(p.stock_quantity, 10) - 1,
                                        p.grid_attribute_id || null,
                                        index
                                      )
                                    }
                                  >
                                    -
                                  </button>
                                  <input
                                    type="text"
                                    className={
                                      p.stock_quantity === 0 ? 'no-stock' : ''
                                    }
                                    value={p.stock_quantity}
                                  />
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleAlterStock(
                                        p.id,
                                        parseInt(p.stock_quantity, 10) + 1,
                                        p.grid_attribute_id || null,
                                        index
                                      )
                                    }
                                  >
                                    +
                                  </button>
                                </DivStockProductsRight>
                              </div>
                            ))}
                        </DivStockProducts>
                      )}
                    </>
                  ) : (
                    ''
                  )}

                  {indexTab === 2 ? <TabPromotion /> : ''}
                  {indexTab === 3 ? <TabAbout /> : ''}
                  {indexTab === 4 ? <MyAccount /> : ''}
                </DivBodyRight>
              </>
            ) : (
              ''
            )}

            {indexMenu === 1 ? (
              <DivBodyRight top="66px">
                <MyAccount />
              </DivBodyRight>
            ) : (
              ''
            )}
            {indexMenu === 3 ? (
              <DivBodyRight top="66px">
                <Settings setIndexMenu={setIndexMenu} />
              </DivBodyRight>
            ) : (
              ''
            )}

            {indexMenu === 4 ? (
              <DivBodyRight top="66px">
                <MyOrders />
              </DivBodyRight>
            ) : (
              ''
            )}

            {indexMenu === 5 ? (
              <DivBodyRight top="66px">
                <Freight selectedItem={melhorEnvioParam && code ? 5 : 0} />
              </DivBodyRight>
            ) : (
              ''
            )}
            {indexMenu === 6 ? (
              <DivBodyRight top="66px">
                <MyPlan />
              </DivBodyRight>
            ) : (
              ''
            )}

            {indexMenu === 7 ? (
              <DivBodyRight top="66px">
                <Plans />
              </DivBodyRight>
            ) : (
              ''
            )}

            {indexMenu === 8 ? (
              <DivBodyRight top="66px">
                <IntegrationWithInstagram />
              </DivBodyRight>
            ) : (
              ''
            )}

            {indexMenu === 9 ? (
              <DivBodyRight top="66px">
                <PaymentMethods setIndexMenu={setIndexMenu} />
              </DivBodyRight>
            ) : (
              ''
            )}

            {indexMenu === 10 ? (
              <DivBodyRight top="66px">
                <Coupons />
              </DivBodyRight>
            ) : (
              ''
            )}

            {indexMenu === 11 ? (
              <DivBodyRight top="66px">
                <ConfigurationOrder />
              </DivBodyRight>
            ) : (
              ''
            )}

            {indexMenu === 12 ? (
              <DivBodyRight top="66px">
                <MoreOptionsConfigurations SetIndexMenu={SetIndexMenu} />
              </DivBodyRight>
            ) : (
              ''
            )}

            {indexMenu === 13 ? (
              <DivBodyRight top="66px">
                <ConversionPixel />
              </DivBodyRight>
            ) : (
              ''
            )}

            {indexMenu === 14 ? (
              <DivBodyRight top="66px">
                <Domain />
              </DivBodyRight>
            ) : (
              ''
            )}
          </DivRight>
        </DivBody>

        {showAddVariantModal && (
          <AddVariation>
            <div className="div-bg" onClick={closeModalAddVariation} />
            <DivAddVariation>
              <header>
                <strong>Adicionar variação</strong>
                <img
                  src={CancelIcon}
                  alt="cancel"
                  onClick={closeModalAddVariation}
                />
              </header>

              <div>
                <strong>Nome da variação</strong>
                <span>Exemplo: “Cor”.</span>
                <input
                  type="text"
                  value={inputVariationName}
                  onChange={e => setInputVariationName(e.target.value)}
                />

                <strong>Opções</strong>
                <span>Exemplo: “Azul”, “Amarelo”</span>

                <button type="button" onClick={handleInsertNewSub}>
                  + Adicionar nova opção
                </button>

                <DragDropContext onDragEnd={onDragEndVariations}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <DivItems
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        create
                      >
                        {newSubs &&
                          newSubs.map((ns, index) => (
                            <Draggable
                              key={String(ns.id)}
                              draggableId={String(ns.id)}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <DivItem>
                                    <div>
                                      <img
                                        src={DragAndDropPointsIcon}
                                        alt="points"
                                      />
                                    </div>

                                    <input
                                      type="text"
                                      autoComplete="nope"
                                      defaultValue={ns.name}
                                      onBlur={e =>
                                        handleBlurSub(ns, index, e.target.value)
                                      }
                                    />
                                    <img
                                      src={CancelGreyIcon}
                                      alt="cancel"
                                      onClick={() => handleDeleteSub(ns, index)}
                                    />
                                  </DivItem>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </DivItems>
                    )}
                  </Droppable>
                </DragDropContext>

                {/* <DivItems create>
                  <div>
                    {newSubs &&
                      newSubs.map((ns, index) => (
                        <DivItem key={ns.name}>
                          <input
                            type="text"
                            defaultValue={ns.name}
                            onBlur={e =>
                              handleBlurSub(ns, index, e.target.value)
                            }
                          />
                          <img
                            src={CancelGreyIcon}
                            alt="cancel"
                            onClick={() => handleDeleteSub(ns, index)}
                          />
                        </DivItem>
                      ))}
                  </div>
                </DivItems> */}
              </div>

              <footer>
                <button
                  type="button"
                  onClick={handleSubmitVariation}
                  disabled={disableButtonSaveVariation}
                >
                  Salvar
                </button>
              </footer>
            </DivAddVariation>
          </AddVariation>
        )}

        {showAddSubVariantModal && (
          <AddVariation>
            <div className="div-bg" onClick={closeModalAddVariation} />
            <DivAddVariation>
              <header>
                <strong>Editar variação</strong>
                {/* <img
                  src={CancelIcon}
                  alt="cancel"
                  onClick={closeModalAddVariation}
                /> */}

                <button
                  type="button"
                  onClick={() => handleClickDeleteVariation(variationId, true)}
                >
                  Excluir
                </button>
              </header>

              <div>
                <strong>Nome da variação</strong>
                <span>Exemplo: “Cor”.</span>
                <input
                  type="text"
                  value={inputVariationName}
                  onChange={e => setInputVariationName(e.target.value)}
                />

                <strong>Opções</strong>
                <span>Exemplo: “Azul”, “Amarelo”</span>

                <button type="button" onClick={handleInsertNewSub}>
                  + Adicionar nova opção
                </button>

                <DragDropContext onDragEnd={onDragEndVariations}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <DivItems
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        create
                      >
                        {newSubs &&
                          newSubs.map((ns, index) => (
                            <Draggable
                              key={String(ns.id)}
                              draggableId={String(ns.id)}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <DivItem>
                                    <div>
                                      <img
                                        src={DragAndDropPointsIcon}
                                        alt="points"
                                      />
                                    </div>

                                    <input
                                      type="text"
                                      autoComplete="nope"
                                      defaultValue={ns.name}
                                      onBlur={e =>
                                        handleBlurSub(ns, index, e.target.value)
                                      }
                                    />
                                    <img
                                      src={CancelGreyIcon}
                                      alt="cancel"
                                      onClick={() => handleDeleteSub(ns, index)}
                                    />
                                  </DivItem>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </DivItems>
                    )}
                  </Droppable>
                </DragDropContext>

                {/* <DivItems create>
                  <div>
                    {newSubs &&
                      newSubs.map((ns, index) => (
                        <DivItem key={ns.name}>
                          <input
                            type="text"
                            defaultValue={ns.name}
                            onBlur={e =>
                              handleBlurSub(ns, index, e.target.value)
                            }
                          />
                          <img
                            src={CancelGreyIcon}
                            alt="cancel"
                            onClick={() => handleDeleteSub(ns, index)}
                          />
                        </DivItem>
                      ))}
                  </div>
                </DivItems> */}
              </div>

              <footer>
                <button
                  type="button"
                  onClick={handleSubmitEditVariation}
                  disabled={disableButtonSaveVariation}
                >
                  Salvar
                </button>
              </footer>
            </DivAddVariation>
          </AddVariation>
        )}

        {showAddAddressModal && (
          <AddAddress
            handleCreateTableFlatRate={handleCreateTableFlatRate}
            handleSetShowAddAddress={handleSetShowAddAddress}
            isModal={false}
          />
        )}

        {/* <DivMaintenance>
          <span>Olá! estamos em manutenção, aguarde alguns minutos...</span>
        </DivMaintenance> */}
      </Container>
      {userConfigurations && userConfigurations.maintenance && (
        <LabelMaintenanceMode>
          <span>Você está com o modo manutenção ativado</span>
        </LabelMaintenanceMode>
      )}

      {pro === 0 && (
        <DivProPlan>
          <header>
            <strong>Plano {dataPlans.pro.name}</strong>

            <div>{dataPlans.pro.price.yearly.economyPercent} OFF</div>
          </header>

          <div>
            <strong>R$ {dataPlans.pro.price.yearly.price}</strong>
            <span>R$ {dataPlans.pro.price.yearly.oldPrice}</span>
          </div>

          <button
            type="button"
            onClick={() => {
              seePlansBottombarBannerDesktopEvent();
              setIndexMenu(7);
              setSelectedItem();
            }}
          >
            <span>Quero ser {dataPlans.pro.name}</span>
          </button>
        </DivProPlan>
      )}

      {subscription && subscription.payment_status === 'past_due' && (
        <LabelPastDueSubscription>
          <strong>Assinatura vencida</strong>

          <span>
            Sua assinatura está vencida, pois seu último pagamento falhou,
            atualize sua forma de pagamento.
          </span>
        </LabelPastDueSubscription>
      )}

      {userConfigurations && userConfigurations.status === 'suspended' && (
        <OverduePayment isMobile={false} />
      )}

      {showConfigurationModal && (
        <ConfigurationModal
          SetShowConfigurationModal={SetShowConfigurationModal}
        />
      )}
    </>
  );
}

export default Dashboard;
