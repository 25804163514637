/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/order */
/* eslint-disable react/button-has-type */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useEffect, useState } from 'react';

import CardProPlan from '~/components/ProPlan/Cards/CardProPlan';
import dataPlans from '../../../data/plans.json';

import CardPremiumPlan from '~/components/ProPlan/Cards/CardPremiumPlan';
import CardUltraPlan from '~/components/ProPlan/Cards/CardUltraPlan';
import { useKeenSlider } from 'keen-slider/react';
import ResponsiveHeader from '../../../components/HeaderMobile';

import 'keen-slider/keen-slider.min.css';

import {
  Container,
  DivBody,
  DivButtonsPlanOptions,
  DivDiscount,
  DivLoader,
  DivPlanOptions,
  DivProCards,
  MonthlyButton,
  YearlyButton,
} from './styles';
import { H3, Span } from '~/designsystem/Fonts';
import api from '~/services/api';
import UpdatePlanModal from '~/components/Popups/UpdatePlan';
import LoaderComponent from '~/components/Loader';

function Plans() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [pro, setPro] = useState();
  const [typePlan, setTypePlan] = useState('monthly');
  const [planObject, setPlanObject] = useState({});
  const [showUpdatePlanModal, setShowUpdatePlanModal] = useState(false);
  const [activePlanObject, setActivePlanObject] = useState({});
  const [loading, setLoading] = useState(false);
  const [userConfigurations, setUserConfigurations] = useState();

  const [ref, instanceRef] = useKeenSlider({
    slides: {
      perView: 1.12,
      // spacing: 16,
    },
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
  });

  useEffect(() => {
    async function loadPro() {
      await api.get('/proplan').then(response => {
        setPro(response.data);
      });

      await api.get('/userconfigurations').then(response => {
        setUserConfigurations(response.data);
      });
    }

    loadPro();
  }, []);

  useEffect(() => {
    async function load() {
      try {
        setLoading(true);

        await api.get('/stripe/plan/active').then(response => {
          setActivePlanObject(response.data);
        });
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }

    load();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function handleClickCreatePortalSession() {
    const subscription = await api.get('/subscription');

    if (subscription && subscription.data && subscription.data.customer_id) {
      await api
        .post('/createportalsession', {
          customerId: subscription.data.customer_id,
        })
        .then(response => {
          window.location.href = response.data.url;
        });
    }
  }

  function handleUpdatePlan({
    plan_name,
    stripe_price_id,
    selectedTypePlan,
    price,
  }) {
    setShowUpdatePlanModal(true);
    console.log('plan_name', plan_name, 'stripe_price_id', stripe_price_id);
    setPlanObject({ plan_name, stripe_price_id, selectedTypePlan, price });
  }

  function handleShowUpdatePlanModal(value) {
    setShowUpdatePlanModal(value);
    setPlanObject({});
  }

  return (
    <Container>
      <ResponsiveHeader title="Planos" url="back" tab={1} />

      <DivBody>
        <H3>Selecionar plano</H3>

        <Span>Você pode alterar o plano quando quiser</Span>

        <DivPlanOptions>
          <DivButtonsPlanOptions>
            <MonthlyButton
              type="button"
              onClick={() => setTypePlan('monthly')}
              active={typePlan === 'monthly'}
            >
              Mensal
            </MonthlyButton>

            <YearlyButton
              type="button"
              onClick={() => setTypePlan('yearly')}
              active={typePlan === 'yearly'}
            >
              Anual
              <div>até 63% OFF</div>
            </YearlyButton>
          </DivButtonsPlanOptions>
        </DivPlanOptions>

        <DivDiscount>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
              stroke="#FF6C4B"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 4.2002V9.0002L12.2 10.6002"
              stroke="#FF6C4B"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <span>Descontos por tempo limitado</span>
        </DivDiscount>

        {loading && (
          <DivLoader>
            <LoaderComponent size={32} />
          </DivLoader>
        )}

        {!loading && (
          <>
            <div className="dots">
              {[
                ...Array(
                  instanceRef &&
                    instanceRef.current &&
                    instanceRef.current.track.details.slides.length
                ).keys(),
              ].map(idx => {
                return (
                  <button
                    key={idx}
                    onClick={() => {
                      instanceRef.current.moveToIdx(idx);
                    }}
                    className={`dot${currentSlide === idx ? ' active' : ''}`}
                  />
                );
              })}
            </div>

            <DivProCards>
              <div className="keen-slider" ref={ref}>
                <CardProPlan
                  className="keen-slider__slide"
                  pro={pro}
                  typePlan={typePlan}
                  handleClickCreatePortalSession={
                    handleClickCreatePortalSession
                  }
                  handleUpdatePlan={handleUpdatePlan}
                  showBanner
                  showBorder
                  activePlanObject={activePlanObject}
                  isMyPlan={
                    activePlanObject &&
                    (dataPlans.pro.price[typePlan].stripe_price_id ===
                      activePlanObject.id ||
                      dataPlans.oldPro.stripe_price_id === activePlanObject.id)
                  }
                  suspendedStore={
                    userConfigurations &&
                    userConfigurations.status === 'suspended'
                  }
                />

                <CardPremiumPlan
                  className="keen-slider__slide"
                  pro={pro}
                  typePlan={typePlan}
                  handleClickCreatePortalSession={
                    handleClickCreatePortalSession
                  }
                  handleUpdatePlan={handleUpdatePlan}
                  showBanner
                  showBorder
                  activePlanObject={activePlanObject}
                  isMyPlan={
                    activePlanObject &&
                    dataPlans.premium.price[typePlan].stripe_price_id ===
                      activePlanObject.id
                  }
                  suspendedStore={
                    userConfigurations &&
                    userConfigurations.status === 'suspended'
                  }
                />

                <CardUltraPlan
                  className="keen-slider__slide"
                  pro={pro}
                  typePlan={typePlan}
                  handleClickCreatePortalSession={
                    handleClickCreatePortalSession
                  }
                  handleUpdatePlan={handleUpdatePlan}
                  showBanner
                  showBorder
                  activePlanObject={activePlanObject}
                  isMyPlan={
                    activePlanObject &&
                    dataPlans.ultra.price[typePlan].stripe_price_id ===
                      activePlanObject.id
                  }
                  suspendedStore={
                    userConfigurations &&
                    userConfigurations.status === 'suspended'
                  }
                />
              </div>
            </DivProCards>
          </>
        )}
      </DivBody>

      <UpdatePlanModal
        active={showUpdatePlanModal}
        setActive={handleShowUpdatePlanModal}
        planObject={planObject}
      />
    </Container>
  );
}

export default Plans;
