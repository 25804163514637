import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
// import publicIp from 'public-ip';

import { signupEvent } from '~/functions/events';

import {
  ButtonSubmit,
  Container,
  Div,
  DivInput,
  SpanError,
  Label,
} from './styles';

import api from '../../../services/api';

import {
  signUpRequest,
  signFailure,
} from '../../../store/modules/auth/actions';

import logoIcon from '../../../assets/icons/login/logo.svg';
import checkIcon from '../../../assets/icons/login/check.svg';
import errorIcon from '../../../assets/icons/login/error.svg';
import loaderIcon from '../../../assets/icons/loading-white.gif';

// import api from '../../services/api';

export default function ResponsiveSignup({ indicatedUser }) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [user, setUser] = useState();

  const [isValidEmail, setIsValidEmail] = useState(true);
  const [errorEmail, setErrorEmail] = useState(false);

  const [isValidUser, setIsValidUser] = useState(true);
  const [errorUser, setErrorUser] = useState(false);

  const [isValidPassword, setIsValidPassword] = useState(true);
  const [errorPassword, setErrorPassword] = useState(false);

  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  async function checkEmail(pEmail) {
    if (pEmail) {
      const data = {
        email: pEmail,
      };
      const response = await api.post('/checkemail', data);

      setIsValidEmail(!response.data.hasEmail);

      if (response.data.hasEmail) {
        setErrorEmail(response.data.error);
      }
    }
  }

  useEffect(() => {
    const newUser = user && user.replace(/[^a-zA-Z0-9]+/g, '');
    setUser(newUser);
  }, [user]);

  // async function checkEmail() {}

  async function checkUser(pUser) {
    if (pUser) {
      const data = {
        user: pUser.toLowerCase(),
      };
      const response = await api.post('/checkuser', data);

      setIsValidUser(!response.data.hasUser);

      if (response.data.hasUser) {
        setErrorUser(response.data.error);
      }
    }
  }

  async function checkPassword(pPassword) {
    if (pPassword) {
      setIsValidPassword(true);

      if (pPassword.length < 6) {
        setIsValidPassword(false);
        setErrorPassword('Senha deve ter no mínimo 6 caracteres');
      }
    }
  }

  async function handleSubmit() {
    try {
      if (!user || !email || !password) {
        toast.error('Preencha todos os campos!');
        dispatch(signFailure());
        return;
      }

      const data = {
        user: user.toLowerCase(),
      };

      const response = await api.post('/checkuser', data);

      if (response.data.hasUser) {
        toast.error('Usuário não pode conter espaços');
        dispatch(signFailure());
        return;
      }

      // const ipv4 = await publicIp.v4();

      // Meta
      const fbc = Cookies.get('_fbc');
      const fbp = Cookies.get('_fbp');

      // Tiktok
      const ttp = Cookies.get('_ttp');
      const ttclid = Cookies.get('ttclid');
      const affiliate_code = Cookies.get('buzzlead');

      dispatch(
        signUpRequest(
          user.toLowerCase(),
          email.toLowerCase(),
          password,
          indicatedUser || '',
          fbc,
          fbp,
          ttp,
          ttclid,
          affiliate_code
        )
      );

      signupEvent();
    } catch (err) {
      dispatch(signFailure());
    }
  }

  // async function handleClickEvent() {
  //   try {
  //     ReactPixel.track('InitiateCheckout', {
  //       em: email,
  //     });

  //     await ConversionApiMeta({
  //       eventName: 'InitiateCheckout',
  //       email: email.toLowerCase(),
  //       phone: '5551999730203',
  //       id: 1,
  //     });
  //   } catch (err) {
  //     console.log(
  //       'Erro ao enviar evento CompleteRegistration na criação de conta',
  //       err
  //     );
  //   }
  // }

  const injectGA = () => {
    if (typeof window === 'undefined') {
      return;
    }
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());

    gtag('config', 'AW-491873348');
  };

  return (
    <Container>
      <script>{injectGA()}</script>
      <Div>
        <img src={logoIcon} alt="logo" />
        <h1>Criar conta</h1>
        <div>
          <strong>E-mail</strong>
          <DivInput hasError={!isValidEmail}>
            <div>
              <input
                type="email"
                className="input-email"
                placeholder="Seu e-mail"
                onBlur={e => checkEmail(e.target.value)}
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              {isValidEmail && email && <img src={checkIcon} alt="check" />}
              {!isValidEmail && <img src={errorIcon} alt="error" />}
            </div>

            {!isValidEmail && <SpanError>{errorEmail}</SpanError>}
          </DivInput>

          <strong>Senha</strong>
          <DivInput hasError={!isValidPassword}>
            <div>
              <input
                type="password"
                className="input-password"
                placeholder="Mínimo de 6 caracteres"
                onBlur={e => checkPassword(e.target.value)}
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              {isValidPassword && password && (
                <img src={checkIcon} alt="check" />
              )}
              {!isValidPassword && <img src={errorIcon} alt="error" />}
            </div>

            {!isValidPassword && <SpanError>{errorPassword}</SpanError>}
          </DivInput>

          <strong>Nome de usuário</strong>
          <DivInput hasError={!isValidUser}>
            <div>
              <input
                type="text"
                className="input-user"
                placeholder="usuario"
                onBlur={e => checkUser(e.target.value)}
                value={user}
                onChange={e => setUser(e.target.value)}
              />
              {/* <Label hasError={!isValidUser}>.offstore.me</Label> */}

              {/* {isValidUser && user && <img src={checkIcon} alt="check" />}
              {!isValidUser && <img src={errorIcon} alt="error" />} */}
            </div>

            {!isValidUser && <SpanError>{errorUser}</SpanError>}
          </DivInput>

          {/* <strong>Código de acesso</strong>
          <DivInput>
            <div>
              <input
                type="text"
                placeholder="Código"
                value={code}
                onChange={e => setCode(e.target.value)}
              />
            </div>
          </DivInput> */}
          {/* loading */}
          <ButtonSubmit
            type="button"
            onClick={handleSubmit}
            disabled={loading || !user || !email || !password}
          >
            {(loading && <img src={loaderIcon} alt="loader" />) || 'Continuar'}
          </ButtonSubmit>
        </div>
        <span className="terms">
          Ao prosseguir você estará concordando com nossos{' '}
          <a href="https://offstore.me/terms-and-conditions">termos de uso</a>
        </span>
        <span>Já tem uma conta?</span>
        <Link to="/">Entrar</Link>
      </Div>
    </Container>
  );
}
