import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { H2, Span } from '~/designsystem/Fonts';
import api from '~/services/api';
import {
  Body,
  CancelButton,
  CancelPlanButton,
  ConfirmCancelPlanButton,
  Container,
  DivButtons,
  DivConfirmCancelPlan,
  DivConfirmCancelPlanBody,
  DivConfirmCancelPlanButtons,
  DivConfirmCancelPlanContainer,
  DivLoader,
  PaymentButton,
} from './styles';
import LoaderComponent from '../Loader';

export default function OverduePayment({ isMobile }) {
  const [loader, setLoader] = useState(false);
  const [confirmCancelPlan, setConfirmCancelPlan] = useState(false);

  const history = useHistory();
  async function cancelPayment() {
    try {
      setLoader(true);
      await api.post('/subscription/cancel');

      window.location.reload();
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  }

  async function handleClickCreatePortalSession() {
    if (isMobile) {
      history.push('/pro');
    } else {
      history.push('/plans');
    }

    // const subscription = await api.get('/subscription');

    // if (subscription && subscription.data && subscription.data.customer_id) {
    //   await api
    //     .post('/createportalsession', {
    //       customerId: subscription.data.customer_id,
    //       // customerId: 'cus_QlR7d3cTFhMC9a',
    //     })
    //     .then(response => {
    //       window.location.href = response.data.url;
    //     });
    // }
  }

  return (
    <Container>
      <Body>
        <H2>Assinatura cancelada</H2>

        <Span>
          Para continuar utilizando a plataforma normalmente, clique no botão
          “Reativar assinatura”.
          <br />
          <br />
          Se você clicar em “Desativar todos os recusos pagos”, todos os
          recursos pagos da sua conta serão desativados, como domínios, formas
          de pagamento, frete, personalização e outras opções pagas.
        </Span>

        <DivButtons>
          <CancelButton
            type="button"
            onClick={() => setConfirmCancelPlan(true)}
          >
            Desativar todos os recusos pagos
          </CancelButton>
          <PaymentButton
            type="button"
            onClick={() => handleClickCreatePortalSession()}
          >
            Reativar assinatura
          </PaymentButton>
        </DivButtons>
      </Body>

      {confirmCancelPlan && (
        <DivConfirmCancelPlan>
          <DivConfirmCancelPlanContainer
            onClick={() => setConfirmCancelPlan(false)}
          />
          <DivConfirmCancelPlanBody>
            <strong>
              Tem certeza que deseja desativar todos os recursos pagos?
            </strong>

            <span>
              Recursos importantes como meios de pagamento, meios de frete,
              domínio, personalização, pixels de conversão e outros recursos
              serão desativados
            </span>

            <DivConfirmCancelPlanButtons>
              <CancelPlanButton
                type="button"
                onClick={() => setConfirmCancelPlan(false)}
              >
                Cancelar
              </CancelPlanButton>

              <ConfirmCancelPlanButton
                type="button"
                onClick={() => cancelPayment()}
              >
                {loader ? (
                  <DivLoader>
                    <LoaderComponent size={25} white={false} />
                  </DivLoader>
                ) : (
                  'Desativar todos os recusos pagos'
                )}
              </ConfirmCancelPlanButton>
            </DivConfirmCancelPlanButtons>
          </DivConfirmCancelPlanBody>
        </DivConfirmCancelPlan>
      )}
    </Container>
  );
}
