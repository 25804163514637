import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '../../../services/history';
import api from '../../../services/api';

import { signInSuccess, signFailure } from './actions';

export function* signIn({ payload }) {
  try {
    const { user_email, password } = payload;

    const response = yield call(api.post, 'sessions', {
      user_email,
      password,
    });

    const { token, user } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(token, user));

    history.push('/dashboard');
    toast.success('Login realizado!');
  } catch (err) {
    toast.error(err.response.data.error);
  } finally {
    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const {
      user,
      email,
      password,
      indicatedUser,
      meta_fbc,
      meta_fbp,
      tiktok_ttp,
      tiktok_ttclid,
      affiliate_code,
    } = payload;

    const newUser = yield call(api.post, 'users', {
      user,
      email,
      password,
      meta_fbc,
      meta_fbp,
      tiktok_ttp,
      tiktok_ttclid,
      affiliate_code,
    });

    // if (indicatedUser) {
    //   yield call(api.post, 'validateindicationwithlink', {
    //     email: newUser.data.email,
    //     indicatedUser,
    //     ip,
    //   });
    // } else {
    //   yield call(api.post, 'validateindication', {
    //     email: newUser.data.email,
    //     user,
    //   });
    // }

    const response = yield call(api.post, 'sessions', {
      user_email: newUser.data.email,
      password,
    });

    const { token, user: userProfile } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield call(api.post, `attributesdefault/${newUser.data.id}`, {
      user_email: newUser.data.email,
      password,
    });

    yield put(signInSuccess(token, userProfile));

    history.push('/customizeavatar');
    toast.success('Conta criada!');
  } catch (err) {
    toast.error(err.response.data.error);
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
